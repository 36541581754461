import { useState } from 'react'
import { Stack, TextField, TooltipHost, ITooltipHostStyles, DirectionalHint } from '@fluentui/react'
import { SendRegular } from '@fluentui/react-icons'
import Send from '../../assets/Send.svg'
import styles from './QuestionInput.module.css'

interface Props {
  onSend: (question: string, id?: string) => void
  disabled: boolean
  placeholder?: string
  clearOnSend?: boolean
  conversationId?: string
  question: string
  setQuestion: (question: string) => void
}

const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', marginLeft: 10 } }

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId, question, setQuestion }: Props) => {
  const maxCharacterLimit = 1000

  const sendQuestion = () => {
    if (disabled || !question?.trim() || question.length > maxCharacterLimit) {
      return
    }

    if (conversationId) {
      onSend(question, conversationId)
    } else {
      onSend(question)
    }

    if (clearOnSend) {
      setQuestion('')
    }
  }

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
      ev.preventDefault()
      sendQuestion()
    }
  }

  const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setQuestion(newValue || '')
  }

  const sendQuestionDisabled = disabled || !question?.trim() || question.length > maxCharacterLimit

  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextField
        className={`${styles.questionInputTextArea}`}
        placeholder={placeholder}
        multiline
        resizable={false}
        borderless
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        autoAdjustHeight
        size={1}
      />
      <div className={styles.characterCountContainer}>
        {question.length > maxCharacterLimit && (
          <span className={styles.characterCountExceededErrorMessage}>
            Maximum character limit reached. Prompts are restricted to 1000 characters due to LLM model constraints.
          </span>
        )}
        <div className={styles.characterCount}>
          <TooltipHost
            content="Prompts are restricted to 1000 characters due to LLM model limitations. If you're trying to provide a lot of information, it might be best to break it down into multiple prompts."
            styles={tooltipHostStyles}
            calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
            <span className={question.length > maxCharacterLimit ? styles.characterCountExceeded : ''}>
              {question.length}/{maxCharacterLimit}
            </span>
          </TooltipHost>
        </div>
      </div>
      <div
        className={styles.questionInputSendButtonContainer}
        role="button"
        tabIndex={0}
        aria-label="Ask question button"
        onClick={sendQuestion}
        onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null)}>
        {sendQuestionDisabled ? (
          <SendRegular className={styles.questionInputSendButtonDisabled} />
        ) : (
          <img src={Send} className={styles.questionInputSendButton} alt="Send Button" />
        )}
      </div>
    </Stack>
  )
}
