import styles from "./SupportingContent.module.css";
import { Citation } from "../../api";

interface Props {
    citation: Citation | undefined;
}

export const SupportingContent = ({ citation }: Props) => {
    if (!citation) {
        return null;
    }

    const textItems = [citation.content];
    // const imageItems = !Array.isArray(supportingContent) ? supportingContent?.images : [];
    return (
        <ul className={styles.supportingContentNavList}>
            {textItems.map(c => {
                return <TextSupportingContent {...citation} />;
            })}
            {/* {imageItems?.map(i => {
                return <img className={styles.supportingContentItemImage} src={i.url} />;
            })} */}
        </ul>
    );
};

export const TextSupportingContent = ( citation : Citation) => {
    return (
        <li className={styles.supportingContentItem}>
            <h4 className={styles.supportingContentItemHeader}>{citation.title}</h4>
            <p className={styles.supportingContentItemText} dangerouslySetInnerHTML={{ __html: citation.content }} />
        </li>
    );
};
