import React from 'react';
import styles from './ExampleCard.module.css';

interface ExampleCardProps {
  className?: string;
  title: string;
  icon: React.ComponentType;
  topBorder: string;
  contents: React.ReactNode[];
}

const ExampleCard: React.FC<ExampleCardProps> = ({ className, title, icon: Icon, topBorder, contents }) => {
  return (
    <div className={`${styles.rectangle} ${className ? className : ''}`}>
      <div className={topBorder} />
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.contentsGrid}>
        {contents.map((content, index) => (
          <div key={index}>
            {content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExampleCard;