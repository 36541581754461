import React from 'react'
import styles from './ExampleCard.module.css'
import ExampleCard from './ExampleCard'
import { Example } from './Example'
import { LightbulbFilamentRegular } from '@fluentui/react-icons'

const EXAMPLE_1 = "Hi! Describe Wood Group and the projects they have carried out. Describe how Wood Group is performing financially. Format it as a Press Release for Immediate Release"
const EXAMPLE_2 = "Hi! Help me to create a generic press release about the work wood group has carried out in Sustainability. We want to highlight the various projects and research it has carried out. Format it as a Press Release for Immediate Release."
const EXAMPLE_3 = "Hi! Provide an overview of the work Wood Group has carried out regarding Hydrogen and summarise their Hydrogen research and projects. Format it as a Press Release for Immediate Release"
const EXAMPLE_4 = "Hi! Provide an overview of Wood Group's initiatives surrounding ESG. Includes figures and appropriate quotes. Include who the quotes belong to. Format it as a Press Release for Immediate Release"


interface Props {
  exampleClickHandler: (text: string) => void;
}

const ExampleCards: React.FC<Props> = ({ exampleClickHandler }) => {
  return (
    <div className={styles.rectangleContainer}>
      <ExampleCard
        title="Prompt examples"
        icon={LightbulbFilamentRegular}
        topBorder={styles.cardBorder}
        contents={[
          <Example 
            key="1" 
            displayedText="Provide information about Wood Group and a general overview of its financial performance"
            promptText={EXAMPLE_1}
            onClick={exampleClickHandler}/>,
          <Example 
            key="2" 
            displayedText="Create a generic Press Release for Wood Group highlighting its progress witin Sustainability"
            promptText={EXAMPLE_2}
            onClick={exampleClickHandler}/>,
          <Example 
            key="3" 
            displayedText="Provide a Press Release highlighting the work Wood Group has done regarding Hydrogen"
            promptText={EXAMPLE_3}
            onClick={exampleClickHandler}/>,
            <Example 
            key="4" 
            displayedText="Provide a Press Release highlighting the ESG initiatives within Wood Group including key figures and facts"
            promptText={EXAMPLE_4}
            onClick={exampleClickHandler}/>,
        ]}
      />
    </div>
  )
}
export default ExampleCards
