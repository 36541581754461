import styles from './ExampleCard.module.css';

interface ExampleProps {
    displayedText: string;
    promptText: string;
    key: string;
    onClick?: (text: string) => void;
}

export const Example = ({displayedText, promptText, key, onClick}:ExampleProps) => {
    const buttonClass = onClick ? `${styles.example} ${styles.exampleClickable}` : styles.example

    return (
        <button key={key} className={buttonClass} onClick={() => onClick?.(promptText)}>
            {displayedText}
        </button>
    )
}