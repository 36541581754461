import { useState, ReactNode, ElementType } from 'react';
import { Stack, Text } from '@fluentui/react';
import { Add20Regular, Subtract20Regular } from '@fluentui/react-icons';
import styles from './CustomAccordion.module.css'

interface CustomAccordionProps {
    title: string;
    children: ReactNode;
    icon?: ElementType;
    className?: string;
}

export const CustomAccordion = ({ title, children, icon: Icon , className }:CustomAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack className = { className } tokens={{ childrenGap: 0 }}>
      <Stack className={isOpen ? styles.open : ''} horizontal horizontalAlign="space-between" verticalAlign="center" onClick={toggleOpen}>
        <div>
          {Icon && <Icon className={styles.accordionIcon}/>}
        </div>
        <div className={styles.titleDiv}>
          <Text className={styles.title} variant="large">{title}</Text>
        </div>
        <div>
          {isOpen ? <Subtract20Regular className={styles.subtractIcon}/> : <Add20Regular className={styles.addIcon}/>}
        </div>
      </Stack>
      {isOpen && <Stack className={styles.childStack}>{children}</Stack>}
    </Stack>
  );
};