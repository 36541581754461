import React, { useState } from 'react'
import { DismissCircle16Regular } from '@fluentui/react-icons'
import styles from './PrivacyNotice.module.css'

const PRIVACY_DOC_URL = "https://www.woodplc.com/footer/your-privacy-and-accessibility/privacy-notice"

const PrivacyNotice = () => {
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = () => {
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <div className={styles.privacyNoticeContainer}>
      <div className={styles.privacyNoticeHeader}>
        <DismissCircle16Regular className={styles.dismissIcon} onClick={handleClose} />
      </div>
      <h4 className={styles.privacyNotice}>
      MediaGen values your privacy and is committed to protecting your personal information. 
      Any data you share with MediaGen, including personal details and content related to your press releases, 
      will be used solely for the purpose of generating and improving press releases. 
      We do not sell, share, or disclose your information to third parties without your consent, except as required by law. 
      All data is stored securely and handled in accordance with applicable privacy regulations. 
      By using MediaGen, you agree to our privacy practices as outlined in this notice.{' '}
        <a href={PRIVACY_DOC_URL} target="_blank" rel="noopener noreferrer">
          {' '}
          Learn more
        </a>
      </h4>
    </div>
  )
}

export default PrivacyNotice
