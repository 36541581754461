import { useContext } from 'react'
import styles from './SideMenu.module.css';
import { Text, PrimaryButton, DefaultButton } from '@fluentui/react';
import { NavigationFilled, ChatRegular, AddRegular, Bookmark20Regular, People20Regular, InfoRegular } from '@fluentui/react-icons';
import ChatHistoryList from '../ChatHistory/ChatHistoryList'
import { CustomAccordion } from '../common/CustomAccordion'
import { AppStateContext } from '../../state/AppProvider'

const CONSIDERATION_1 = "This App is limited to drafting text based responses. Do not treat outputs as final without human review."
const CONSIDERATION_2 = "This App is restricted to answering questions from pre-approved content that is loaded into the system. It is not connected to the Internet."
const CONSIDERATION_3 = "This App is not intended for resourcing decisions or financial planning"

const COMMUNITY_LINK = "https://engage.cloud.microsoft/main/org/groupecgi.onmicrosoft.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI0NjA3NTQ4NjIwOSJ9/all"
const RESPONSIBLE_USE_LINK = "https://cgi.percipio.com/linked-contents/e425983c-3cca-41f7-92ad-f599e4420240/landing"
const PROMPT_ENG_1_LINK = "https://cgi.percipio.com/linked-contents/51a6d12c-d108-493b-a8ff-4738f6b3dba7/landing"
const PROMPT_ENG_2_LINK = "https://cgi.percipio.com/linked-contents/e5123f21-d79d-413a-9b43-eb23f108b8e5/landing"
const BIDGEN_DEMO_LINK = "https://groupecgi.sharepoint.com/:v:/t/PRJ00022868/Ee-ZOYxNx0FHgQYRzzR4gPUBVaraYwkdvylEFlxuMieS3A?e=LyHcjZ"
const USING_CRM_LINK = "https://ensembleus.ent.cgi.com/business/128293/_layouts/15/DocIdRedir.aspx?ID=YJJP6TYX76ZH-2015282741-226"

interface SideMenuProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

export const SideMenu = ({ isMenuOpen, toggleMenu }: SideMenuProps) => {

  const appStateContext = useContext(AppStateContext)

  const newChat = () => {
    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null })
  }

  return (
    <div className={`${styles.menuContainer} ${isMenuOpen ? styles.sideMenuOpen : ''}`}>
      <NavigationFilled 
          onClick={toggleMenu} 
          className={styles.hamburgerIcon} 
          primaryFill={'white'}
      />  
      <PrimaryButton className={`${styles.newChatButton} ${isMenuOpen ? styles.show : ''}`} onClick={newChat}>
        <AddRegular className={styles.addIcon} primaryFill={'white'} />
        New Chat
      </PrimaryButton>
      <div className={`${styles.chatHistory} ${isMenuOpen ? styles.show : ''}`}>
        <div className={styles.menuItemTitle}>
          <ChatRegular className={styles.menuIcon} primaryFill={'white'} />
          <Text variant="large" className={styles.title}>Chat History</Text>
        </div>
        <ChatHistoryList />
      </div>
      <CustomAccordion className={styles.accordion} title="Application considerations" icon={InfoRegular}>
        <p className={styles.accordionTextItem}>{CONSIDERATION_1}</p>
        <p className={styles.accordionTextItem}>{CONSIDERATION_2}</p>
        <p className={styles.accordionTextItem}>{CONSIDERATION_3}</p>
      </CustomAccordion>
    </div>
  );
};