import { Pivot, PivotItem } from '@fluentui/react'

import styles from './AnalysisPanel.module.css'

import { SupportingContent } from '../SupportingContent'
import { Citation, getContent } from '../../api'
import { AnalysisPanelTabs } from './AnalysisPanelTabs'
// import { ThoughtProcess } from "./ThoughtProcess";

import { useState, useEffect } from 'react'

interface Props {
  className: string
  activeTab: AnalysisPanelTabs
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void
  activeCitation: Citation | undefined
  citationHeight?: string
}

const pivotItemDisabledStyle = { disabled: true, style: { color: 'grey' } }

export const AnalysisPanel = ({ activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
  // const isDisabledThoughtProcessTab: boolean = !answer.choices[0].context.thoughts;
  const isDisabledSupportingContentTab: boolean = !activeCitation!.content
  const isDisabledThoughtProcessTab: boolean = true
  const isDisabledCitationTab: boolean = !activeCitation
  const [citation, setCitation] = useState('')

  const fetchCitation = async () => {
    if (activeCitation) {
      const citationContent = await getContent(activeCitation.url!)
      if (citationContent) {
        const citationObjectUrl = URL.createObjectURL(citationContent)
        setCitation(citationObjectUrl)
      }
    }
  }
  useEffect(() => {
    fetchCitation()
  }, [activeCitation])

  return (
    <Pivot
      className={`${className} ${styles.AnalysisPanel}`}
      selectedKey={activeTab}
      onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}>
      {/* <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                { <ThoughtProcess thoughts={answer.choices[0].context.thoughts || []} /> }
            </PivotItem> */}
      <PivotItem
        itemKey={AnalysisPanelTabs.SupportingContentTab}
        headerText="Supporting content"
        headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}>
        <SupportingContent citation={activeCitation} />
      </PivotItem>
      <PivotItem
        itemKey={AnalysisPanelTabs.CitationTab}
        headerText="Citation"
        headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}>
        {activeCitation?.filepath?.endsWith('.png') ? (
          <img src={citation} className={styles.citationImg} />
        ) : activeCitation?.filepath?.endsWith('.pdf') ? (
          <iframe title="Citation" src={citation} width="100%" height={citationHeight || '810px'} />
        ) : (
          <div>
            <p>This file type cannot be rendered in the browser, but you can download it to view it.</p>
            <button
              onClick={() => {
                const filename = activeCitation?.filepath || 'file'

                const link = document.createElement('a')
                link.href = citation
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }}>
              Download File
            </button>
          </div>
        )}
      </PivotItem>
    </Pivot>
  )
}
